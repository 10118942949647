import React from 'react'

import MainBg from '../components/backgrounds/MainBg'
import GradientBg from '../components/backgrounds/GradientBackground'
import FirstSection from '../components/sections/FirstSection'
import SecondSection from '../components/sections/SecondSection'

import MainButton from '../components/buttons/MainButton'
import FeatureCard from '../components/cards/FeatureCard'
import RowFeatureCard from '../components/cards/RowFeatureCard'

import MainSlider from '../components/slider/MainSlider'

import CognideXLogo from '../assets/logo/cognidex-logo.svg';
import ThirdSection from '../components/sections/ThirdSection'
import ForthSection from '../components/sections/ForthSection'

const Home = () => {
    const cardData = [
        {
            logo: CognideXLogo,
            title: "Utility",
            description: <>The CGDX token is the UTILITY token for the DATAPOOL</>,
        },
        {
            logo: CognideXLogo,
            title: "Buy and Sell",
            description: <>Buy and Contribute data using the CGDX token</>,
        },
        {
            logo: CognideXLogo,
            title: "Data Monetization",
            description: <>Monetize your data and GET PAID in CGDX</>,
        },
    ];

    const open_new_tab = (url) => {
        window.open(url, '_blank');
    }

    return (
        <MainBg>
            <GradientBg>
                <FirstSection>
                    <div>
                        <h4>
                            CognideX Decentralized Data Exchange
                        </h4>
                        <h1>
                            Own your data,
                        </h1>
                        <h1>
                            Shape the future.
                        </h1>
                    </div>
                    <h4>
                        Start contributing to the DataPool and Monetize your data today<br></br>
                        Multiply your earnings by contributing early! <a href='https://docs.cognidex.ai/Events&Programs/early-contributors' target='_blank' rel='noreferrer'>Learn more</a>
                    </h4>
                    <div className='button-container'>
                        <div className='button-container'>
                            <MainButton header='Contribute' subheader={<>Earn CGDX <br></br>by uploading your data</>} onClick={() => window.location.href = '/datapool?role=contribute'} />
                        </div>
                        <div className='button-container'>
                            <MainButton header='Tutorials' subheader={<>How to start <br></br>contributing and earning</>} onClick={() => open_new_tab('https://docs.cognidex.ai/Learn/contribute-data-pool')} />
                        </div>
                    </div>
                </FirstSection>
                <ThirdSection>
                    <h1>
                        Supercharged<br></br> Data Contribution
                    </h1>
                    <h4>
                        Boost your rewards by up to 1000% CGDX when you join DataPools early!
                    </h4>
                    <div className='card-collection'>
                        <FeatureCard logo={CognideXLogo} title="Buy Data" description={<>Access the highest quality data from the DataPool at a fraction of the cost!</>} onClick={() => window.location.href = '/datapool?role=buy'} />
                        <FeatureCard logo={CognideXLogo} title="Contribute Data" description={<>Share your valuable data with the DataPool and turn it into profit!</>} onClick={() => window.location.href = '/datapool?role=contribute'} />
                    </div>
                </ThirdSection>
                <SecondSection>
                    <h1>
                        Data Grants
                    </h1>
                    <h4>
                        We're offering 10M CGDX tokens to innovators who need data to build solutions.<br></br>
                        Tell us why you need the data and how you plan to use it for maximum impact below.
                    </h4>
                    <div className='card-collection'>
                        <FeatureCard logo={CognideXLogo} title="Read more" description={<>Learn more about the Data Grants and how you can apply for them</>} onClick={() => open_new_tab('https://docs.cognidex.ai/Grants/data-grants')} />
                        <FeatureCard logo={CognideXLogo} title="Apply now" description={<>Fill in the form to apply for the Data Grants</>} onClick={() => window.location.href = 'https://forms.office.com/r/YXreY6iHjY'} />
                    </div>
                </SecondSection>

                <ForthSection>
                    <div className='card-collection'>
                        {cardData.map((card, index) => (
                            <div className='card' key={index}>
                                <FeatureCard
                                    logo={card.logo}
                                    title={card.title}
                                    description={card.description}
                                />
                            </div>
                        ))}
                    </div>
                    <div className='card-slider'>
                        <MainSlider slideWidth={100}>
                            {cardData.map((card, index) => (
                                <RowFeatureCard
                                    key={index}
                                    logo={card.logo}
                                    title={card.title}
                                    description={card.description}
                                    direction='left'
                                    minHeight='300px'
                                />
                            ))}
                        </MainSlider>
                    </div>
                    <h1>
                        The CGDX Token
                    </h1>
                    <h4 id="footer">
                        A token that emcompasses data like never before, buying and selling data all using CGDX
                    </h4>
                </ForthSection>
            </GradientBg>
        </MainBg>
    )
}

export default Home
